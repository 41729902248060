@import '~react-image-gallery/styles/css/image-gallery.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

/*body, html{*/
/*    font-family: 'Poppins', sans-serif;*/
/*}*/
@media only screen and (max-width: 768px) {
  .site-logo {
    width: 220px !important;
    height: auto;
  }
}

:root {
  --green: #259447;
  --new-dark-main: #18642f;
}

/* .navbar-brand img {
  width: 180px;
  height: 51px;
} */

/*.causes-thumb {*/
/*    position: relative;*/
/*}*/

/*.padding-15 {*/
/*    padding: 15px;*/
/*}*/

/*.causes-content img {*/
/*    width: 100%;*/
/*}*/

/*.causes-content .donate-btn {*/
/*    position: absolute;*/
/*    top: 10px;*/
/*    right: 10px;*/
/*    background-color: rgba(248, 184, 100, 0.8);*/
/*    color: #fff;*/
/*    font-size: 14px;*/
/*    font-family: "Jost", sans-serif;*/
/*    font-weight: 500;*/
/*    padding: 5px 10px;*/
/*    border-radius: 3px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/

/*.causes-content .donate-btn i {*/
/*    margin-left: 5px;*/
/*    font-size: 12px;*/
/*}*/

/*[class*=" ti-"], [class^=ti-] {*/
/*    font-family: themify, serif;*/
/*    speak: none;*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-variant: normal;*/
/*    text-transform: none;*/
/*    line-height: 1;*/
/*    -webkit-font-smoothing: antialiased;*/
/*    -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*.causes-thumb .progress {*/
/*    position: absolute;*/
/*    left: 0;*/
/*    bottom: 0;*/
/*    width: 100%;*/
/*    height: 10px;*/
/*    border-radius: 0;*/
/*    background-color: transparent;*/
/*    overflow: inherit;*/
/*}*/

/*.progress {*/
/*    display: -webkit-box;*/
/*    display: -ms-flexbox;*/
/*    display: flex;*/
/*    height: 1rem;*/
/*    overflow: hidden;*/
/*    font-size: .75rem;*/
/*    background-color: #e9ecef;*/
/*    border-radius: 0.25rem;*/
/*}*/

/*.causes-thumb .progress .progress-bar {*/
/*    background-color: #f8b864;*/
/*    position: relative;*/
/*}*/

/*.progress-bar {*/
/*    display: -webkit-box;*/
/*    display: -ms-flexbox;*/
/*    display: flex;*/
/*    -webkit-box-orient: vertical;*/
/*    -webkit-box-direction: normal;*/
/*    -ms-flex-direction: column;*/
/*    flex-direction: column;*/
/*    -webkit-box-pack: center;*/
/*    -ms-flex-pack: center;*/
/*    justify-content: center;*/
/*    color: #fff;*/
/*    text-align: center;*/
/*    background-color: #007bff;*/
/*    transition: width .6s ease;*/
/*}*/

/*.causes-thumb .progress .progress-bar span {*/
/*    position: absolute;*/
/*    display: block;*/
/*    background-color: #f8b864;*/
/*    right: -2px;*/
/*    top: -10px;*/
/*    height: 30px;*/
/*    width: 30px;*/
/*    line-height: 30px;*/
/*    border-radius: 50%;*/
/*    font-weight: 600;*/
/*    font-size: 12px;*/
/*}*/

/*.cssanimation.fadeInLeft {*/
/*    animation-name: bfadeInLeft;*/
/*}*/

/*.cssanimation {*/
/*    animation-duration: 0.9s;*/
/*    animation-fill-mode: both;*/
/*}*/

/*.causes-thumb .progress .progress-bar span {*/
/*    position: absolute;*/
/*    display: block;*/
/*    background-color: #f8b864;*/
/*    right: -2px;*/
/*    top: -10px;*/
/*    height: 30px;*/
/*    width: 30px;*/
/*    line-height: 30px;*/
/*    border-radius: 50%;*/
/*    font-weight: 600;*/
/*    font-size: 12px;*/
/*}*/

/*.cssanimation.fadeInLeft {*/
/*    animation-name: bfadeInLeft;*/
/*}*/

/*.cssanimation {*/
/*    animation-duration: 0.9s;*/
/*    animation-fill-mode: both;*/
/*}*/

/*.causes-details {*/
/*    background-color: #fff;*/
/*    padding: 40px 30px;*/
/*    border: 1px dashed #ccc;*/
/*    padding-top: 25px;*/
/*}*/

/*.causes-details h3 {*/
/*    font-size: 24px;*/
/*    line-height: 30px;*/
/*}*/

/*.causes-content .read-more {*/
/*    color: #999;*/
/*}*/

/*.donation-box p {*/
/*    display: inline-block;*/
/*    margin-right: 10px;*/
/*}*/

/*.donation-box i {*/
/*    color: #f8b864;*/
/*    margin-right: 5px;*/
/*}*/

/*.causes-thumb .progress .progress-bar span:before {*/
/*    width: 50px;*/
/*    height: 50px;*/
/*    content: "";*/
/*    position: absolute;*/
/*    left: -10px;*/
/*    top: -10px;*/
/*    border-radius: 50%;*/
/*    border: 10px solid rgba(248, 184, 100, 0.8);*/
/*}*/

/* ==========================================================================
   5.0 Causes Section
   ========================================================================== */

.bg-img-scroll-section {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 20px 250px rgb(0, 0, 0);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.bg-img-scroll-section::before {
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 40px 200px rgb(12, 12, 12);
}
.bg-img-scroll {
  min-height: 300px;
}

.list-group-dotted > .list-group-item::before {
  content: '●';
}

.bg-main {
  background-color: var(--green);
}

.btn-main {
  background-color: var(--green);
}
.btn-main:hover {
  background-color: var(--new-dark-main);
}

.dull {
  opacity: 0.3;
  background: rgb(59, 51, 51);
}
.dull:hover {
  opacity: 1;
}

.dull-item {
  background: linear-gradient(
    180deg,
    rgb(0, 0, 0),
    rgba(0, 0, 0, 0.815),
    rgba(0, 0, 0, 0.541),
    transparent
  );
}
.dull-item:hover {
  background: none;
}

.text-main {
  color: var(--green);
}
.top-3-cause-title {
  color: var(--green);
}
.top-3-cause-title:hover {
  color: var(--new-dark-main);
}

.bg-dark-main {
  background-color: var(--new-dark-main);
}

.nav-item-hover:hover {
  background-color: var(--new-dark-main);
}

.divider {
  display: flex;
  flex-direction: row;
}

.divider:before {
  content: '';
  flex: 1 1;
  margin: auto;
  background: linear-gradient(to right, var(--green) 4px, transparent);
  height: 5px;
}

.divider:after {
  content: '';
  flex: 1 1;
  margin: auto;
  background: linear-gradient(to left, var(--green), transparent);
  height: 5px;
}

.divider-thin {
  display: flex;
  flex-direction: row;
}

.divider-thin:before {
  content: '';
  flex: 1 1;
  margin: auto;
  background: linear-gradient(to left, var(--green), transparent);
  height: 4px;
}
.divider-thin:after {
  content: '';
  flex: 1 1;
  margin: auto;
  background: linear-gradient(to right, var(--green), transparent);
  height: 4px;
}
.flex-1 {
  flex: 1 !important;
}
.icon-shadow {
  /* filter: drop-shadow(-1.5px 3px 1px #3b3434b0); */
  filter: drop-shadow(0px 0px 3px black);
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

  transform: scale(1);
  transition: 0.7s ease;
}
.box-shadow:hover {
  transition: 0.7s ease;
  box-shadow: none;
}

.causes-section {
  position: relative;
  z-index: 1;
}

.causes-content img {
  width: 100%;
}

.causes-thumb {
  position: relative;
}

.causes-thumb img {
  width: 100%;
  max-height: 250px;
  min-height: 250px;
  /*position: absolute;*/
}

.causes-thumb img:hover {
  opacity: 0.8;
}

.causes-thumb .progress {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10px;
  border-radius: 0;
  background-color: transparent;
  overflow: inherit;
}

.causes-thumb .progress .progress-bar {
  background-color: #f8b864;
  position: relative;
}

.causes-thumb .progress .progress-bar span {
  position: absolute;
  display: block;
  background-color: #f8b864;
  right: -2px;
  top: -10px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 12px;
}

.causes-thumb .progress .progress-bar span:before {
  width: 50px;
  height: 50px;
  content: '';
  position: absolute;
  left: -10px;
  top: -10px;
  border-radius: 50%;
  border: 10px solid rgba(248, 184, 100, 0.8);
}

.causes-details {
  background-color: #fff;
  padding: 20px 10px;
  border: 1px dashed #ccc;
  padding-top: 25px;
}

.causes-details h3 {
  font-size: 24px;
  line-height: 30px;
}

.causes-content .donate-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(248, 184, 100, 0.8);
  color: #fff;
  font-size: 14px;
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.causes-content .donate-btn i {
  margin-left: 5px;
  font-size: 12px;
}

.causes-content .donate-btn:hover {
  background-color: #f8b864;
  transition: all 0.2s ease-in-out;
}

.donation-box p {
  display: inline-block;
  margin-right: 10px;
}

.donation-box i {
  color: #f8b864;
  margin-right: 5px;
}

.causes-content .read-more {
  color: #999;
}

.causes-content .read-more:hover {
  color: #f8b864;
  text-decoration: underline;
}

/*************************************************************
Fade in
**************************************************************/
.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.cssanimation.fadeInLeft {
  animation-name: bfadeInLeft;
}

@keyframes bfadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-150px);
  }
  to {
    opacity: 1;
  }
}

.cssanimation.fadeInRight {
  animation-name: bfadeInRight;
}

@keyframes bfadeInRight {
  from {
    opacity: 0;
    transform: translateX(150px);
  }
  to {
    opacity: 1;
  }
}

.cssanimation.fadeInTop {
  animation-name: bfadeInTop;
}

@keyframes bfadeInTop {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
  }
}

.cssanimation.fadeInBottom {
  animation-name: bfadeInBottom;
}

@keyframes bfadeInBottom {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
  }
}

/**************************************************************
Fade Out
**************************************************************/
.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

.fadeOutTop {
  animation-name: fadeOutTop;
}

@keyframes fadeOutTop {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.fadeOutBottom {
  animation-name: fadeOutBottom;
}

@keyframes fadeOutBottom {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

/*  Cause Details   */
/*@media (max-width: 767px) {*/
/*    .projects__thumb img {*/
/*        width: 100%;*/
/*    }*/
/*}*/
/*@media only screen and (min-width: 576px) and (max-width: 767px) {*/
/*    .projects__thumb img {*/
/*        width: inherit;*/
/*    }*/
/*}*/

/*.projects__content {*/
/*    padding: 40px 40px 0 35px;*/
/*}*/
/*@media only screen and (min-width: 992px) and (max-width: 1200px) {*/
/*    .projects__content {*/
/*        padding: 40px 15px 0 15px;*/
/*    }*/
/*}*/
/*@media only screen and (min-width: 768px) and (max-width: 991px) {*/
/*    .projects__content {*/
/*        padding: 40px 25px 0 25px;*/
/*    }*/
/*}*/
/*@media (max-width: 767px) {*/
/*    .projects__content {*/
/*        padding: 40px 15px 30px 15px;*/
/*    }*/
/*}*/
/*@media only screen and (min-width: 576px) and (max-width: 767px) {*/
/*    .projects__content {*/
/*        padding: 21px 15px 0px 15px;*/
/*    }*/
/*}*/
/*.projects__content h4 {*/
/*    margin-bottom: 22px;*/
/*    line-height: 1.3;*/
/*}*/
/*@media only screen and (min-width: 992px) and (max-width: 1200px) {*/
/*    .projects__content h4 {*/
/*        font-size: 18px;*/
/*    }*/
/*}*/
/*@media (max-width: 767px) {*/
/*    .projects__content h4 {*/
/*        font-size: 20px;*/
/*    }*/
/*}*/
/*.projects:hover .projects__content h4 {*/
/*    color: #02b663;*/
/*}*/

/*.projects-02 .projects__thumb {*/
/*    overflow: hidden;*/
/*}*/
/*.projects-02 .projects__thumb img {*/
/*    width: 100%;*/
/*    display: inline-block;*/
/*    -webkit-transition: all 0.4s ease-out 0s;*/
/*    -moz-transition: all 0.4s ease-out 0s;*/
/*    -ms-transition: all 0.4s ease-out 0s;*/
/*    -o-transition: all 0.4s ease-out 0s;*/
/*    transition: all 0.4s ease-out 0s;*/
/*}*/
/*.projects-02 .new-tag {*/
/*    color: #1a1e2d;*/
/*    font-size: 12px;*/
/*    font-weight: 700;*/
/*    text-transform: uppercase;*/
/*    background: white;*/
/*    padding: 6px 13px;*/
/*    border-radius: 30px;*/
/*    display: inline-block;*/
/*    position: absolute;*/
/*    right: 15px;*/
/*    top: 15px;*/
/*    line-height: 1;*/
/*}*/
/*.projects-02 .projects__content {*/
/*    padding-bottom: 45px;*/
/*    position: relative;*/
/*}*/
/*.projects-02 .projects__content::before {*/
/*    position: absolute;*/
/*    content: "\f10c";*/
/*    font-family: "Flaticon";*/
/*    bottom: 40px;*/
/*    color: #ededed;*/
/*    font-size: 85px;*/
/*    line-height: 1;*/
/*    opacity: 0.7;*/
/*    right: 45px;*/
/*    top: 40px;*/
/*    z-index: -1;*/
/*    -webkit-transition: all 0.4s ease-out 0s;*/
/*    -moz-transition: all 0.4s ease-out 0s;*/
/*    -ms-transition: all 0.4s ease-out 0s;*/
/*    -o-transition: all 0.4s ease-out 0s;*/
/*    transition: all 0.4s ease-out 0s;*/
/*}*/
/*.projects-02 .project-manager li:first-child {*/
/*    margin-right: 70px;*/
/*}*/
/*@media only screen and (min-width: 992px) and (max-width: 1200px) {*/
/*    .projects-02 .project-manager li:first-child {*/
/*        margin-right: 30px;*/
/*    }*/
/*}*/
/*@media only screen and (min-width: 768px) and (max-width: 991px) {*/
/*    .projects-02 .project-manager li:first-child {*/
/*        margin-right: 35px;*/
/*    }*/
/*}*/
/*@media (max-width: 767px) {*/
/*    .projects-02 .project-manager li:first-child {*/
/*        margin-right: 35px;*/
/*    }*/
/*}*/
/*.projects-02:hover .projects__thumb img {*/
/*    transform: scale(1.1);*/
/*}*/
/*.projects-02:hover .projects__content::before {*/
/*    color: #02b663;*/
/*    transform: scale(1.1);*/
/*    opacity: 0.3;*/
/*}*/

/*.feature-project-03 .custom-container-4 {*/
/*    max-width: 1605px;*/
/*    margin: 0 auto;*/
/*}*/

/*.projects__03 .projects__thumb img {*/
/*    width: 100%;*/
/*}*/
/*.projects__03 .projects__content {*/
/*    padding: 30px 30px 30px 30px;*/
/*    margin: -50px 30px 0 30px;*/
/*    position: relative;*/
/*    z-index: 1;*/
/*    bottom: 0;*/
/*}*/
/*@media only screen and (min-width: 1200px) and (max-width: 1600px) {*/
/*    .projects__03 .projects__content {*/
/*        padding: 30px 20px 30px 20px;*/
/*        margin: -50px 0px 0 0px;*/
/*    }*/
/*}*/
/*@media (max-width: 767px) {*/
/*    .projects__03 .projects__content {*/
/*        padding: 30px 20px 30px 20px;*/
/*        margin: -50px 0px 0 0px;*/
/*    }*/
/*}*/

/*.project-01-area .projects-02 {*/
/*    border: 1px solid #e5e5e5;*/
/*}*/

.project-cart .new-tag {
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 700;
  display: inline-block;
  background: #02b663;
  padding: 8px 19px;
  border-radius: 30px;
  margin-bottom: 22px;
}

.project-cart .projects__content h3 {
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 36px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mr-20 {
  margin-right: 20px;
}

.project-cart .projects__manager .name h5 {
  margin-bottom: 0;
}

.project-cart .projects__manager .name address {
  margin-bottom: 0;
}

.project-cart .projects__manager .name address a:nth-child(1) {
  margin-right: 15px;
}

.project-cart .projects__manager .name address a {
  color: #838694;
  font-size: 15px;
  letter-spacing: -0.3px;
}

.project-cart .projects__manager .name address a i {
  color: #02b663;
  margin-right: 5px;
}

.mb-20 {
  margin-bottom: 20px;
}

.project-cart .projects__content .skill-para {
  margin-bottom: 12px;
}

.project-cart p {
  color: #838694;
  margin-bottom: 30px;
}

.skill p {
  color: #414657;
  font-weight: 700;
  margin-bottom: 12px;
}

.skill p span {
  font-size: 18px;
  color: #02b663;
}

.detail-progress {
  height: 5px;
  overflow: visible;
  background-color: #e0e3e1;
  width: 100%;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.detail-progress .progress-bar {
  background-color: #02b663;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
}

.detail-progress .progress-bar h5 {
  position: absolute;
  right: 40px;
  bottom: 12px;
  color: black;
  font-weight: bold;
}

.cart-list li {
  margin-right: 15px;
  list-style: none;
}

.plus-minus {
  display: inline-block;
}

.pos-rel {
  position: relative;
}

.plus-minus input {
  border: 2px solid #e5e5e5;
  border-radius: 30px;
  text-align: center;
  width: 146px;
  height: 56px;
  color: #1a1e2d;
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
  z-index: 1;
}

.plus-minus .updown {
  position: absolute;
  top: 15px;
  left: 24px;
  z-index: 2;
}

.plus-minus .updown.minus {
  left: auto;
  right: 24px;
}

.theme_btn_background {
  background: #fed857 !important;
}

.btn-outline-light-hover:hover {
  color: #3f8755 !important;
}

input[type='checkbox']:checked {
  background-color: #3f8755;
  border: none;
}

.theme_btn {
  overflow: hidden;
  color: #1a1e2d;
  background: #fed857;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  font-family: 'Poppins', sans-serif;
  padding: 22px 38px 22px 38px;
  display: inline-block;
  border-radius: 30px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.4s ease-in-out;
  text-decoration: none;
}

/*  Cause detail page nav-tabs  */
.our-overview-area .nav-tabs-02 {
  display: flex;
  justify-content: start;
}

.nav-tabs {
  border: 0;
  margin: 0 auto;
}

.our-overview-area .nav-tabs-02 .nav-link {
  font-size: 20px;
  width: 255px;
  background: #02b663;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.nav-tabs .nav-link {
  color: #1a1e2d;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: -0.3px;
  text-align: center;
  background-color: white;
  box-shadow: 0px 10px 30px 0px rgb(224 224 224 / 35%);
  width: 370px;
  display: inline-block;
  border: 0;
  border-radius: 0;
  padding: 19px 0;
  border-bottom: 2px solid #02b663;
}

.theme_btn::before {
  top: 0px;
  width: 0px;
  left: auto;
  right: 0px;
  z-index: -1;
  bottom: 0px;
  content: '';
  position: absolute;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
}

.pb-90 {
  padding-bottom: 90px;
}

.pt-35 {
  padding-top: 35px;
}

.mb-45 {
  margin-bottom: 45px;
}

.img-title {
  font-size: 30px;
  font-weight: 600;
}

.mb-10 {
  margin-bottom: 10px;
}

.left-content-box p {
  color: #838694;
}

.mb-40 {
  margin-bottom: 40px;
}

.left-content-box .image-content-thumb img {
  width: 100%;
}

.mb-35 {
  margin-bottom: 35px;
}

.events-details-list h3 {
  font-size: 30px;
  margin-bottom: 12px;
}

.img-title {
  font-size: 30px;
  font-weight: 600;
}

h3 {
  font-size: 28px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  color: #1a1e2d;
  line-height: 1.1;
  margin-top: 0px;
  font-style: normal;
  font-weight: 700;
  text-transform: normal;
  letter-spacing: -0.3px;
}

.events-details-list p {
  color: #838694;
}

p {
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  font-family: 'Poppins', sans-serif;
  color: #000000;
  margin-bottom: 0;
}

.details-list {
  overflow: hidden;
}

.pt-40 {
  padding-top: 40px;
}

.mb-20 {
  margin-bottom: 20px;
}

ul {
  margin: 0px;
  padding: 0px;
}

.left-content-list .details-list li {
  margin-bottom: 42px;
}

.details-list li {
  color: #1a1e2d;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 32px;
  float: left;
  width: 50%;
  padding-left: 50px;
  position: relative;
}

li {
  list-style: none;
}

.left-content-list .details-list li::before {
  color: #02b663;
  top: -10px;
  width: 40px;
  height: 40px;
  border-radius: 0;
}

.details-list li::before {
  content: '\f00c';
  position: absolute;
  font-family: 'Font Awesome 5 pro', serif;
  font-size: 14px;
  color: #674df0;
  text-align: center;
  left: 0;
  top: -7px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #ececec;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  z-index: 1;
}

.mb-30 {
  margin-bottom: 30px;
}

.left-content-list .image-content-thumb img {
  width: 100%;
}

.mb-30 {
  margin-bottom: 30px;
}

.grey-bg {
  background: #f6f3f1;
}

.widget-rewards {
  padding: 35px 40px 35px 40px;
}

.our-overview-area .nav-tabs-02 .nav-link.active {
  background: #fed857;
  color: #000 !important;
}

.nav-tabs .nav-link.active {
  color: rgb(0, 0, 0);
  background: #02b663;
}

.our-overview-area .nav-tabs-02 .nav-link {
  font-size: 20px;
  width: 255px;
  background: #02b663;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.our-overview-area .nav-tabs-02 {
  display: flex;
  justify-content: space-between;
}

.nav-tabs {
  border: 0;
  margin: 0 auto;
}

.our-overview-area {
  margin-top: -30px;
}

.pos-rel {
  position: relative;
}

.card-img-top {
  width: 100% !important;
  height: 20vw !important;
  object-fit: cover;
}

.custom-card {
  /*width: 100% !important;*/
  /*height: 20vw !important;*/
  object-fit: cover;
}

.carousel-caption {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 0px !important;
}
.text-orange {
  color: #ef5d19;
}
.slider-content {
  padding-bottom: 10px;
  position: absolute !important;
  right: 15% !important;
  bottom: 2.25rem !important;
  left: 15% !important;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border-radius: 5px !important;
  box-shadow: rgb(0 0 0 / 15%) 0px 15px 25px, rgb(0 0 0 / 5%) 0px 5px 10px !important;
}
.carousel-caption h3 {
  font-size: 80px;
  color: #ef5d19;
  font-weight: bold;
}

.carousel-caption p {
  font-size: 40px;
  /*color: #259447*/
  color: #fff;
}

.disable-li {
  pointer-events: none;
  opacity: 0.6;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #ffffff !important;
}

.donationTitle {
  width: 35%;
}

.donationOptions {
  width: 65%;
}

@media (max-width: 425px) {
  .donationTitle {
    width: 100%;
  }

  .donationOptions {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .donationTitle {
    width: 100%;
  }

  .donationOptions {
    width: 100%;
    /*flex-direction: column;*/
  }
}

@media (min-width: 769px) and (max-width: 1920px) {
  .donationTitle {
    width: 100%;
  }

  .donationOptions {
    width: 100%;
    /*flex-direction: column;*/
  }
}

.widget-list li a:hover {
  color: white !important;
}

.share-button:hover:not(:active) {
  opacity: 0.75;
}

.comment-btn-disable {
  pointer-events: none;
  opacity: 0.6;
}

.navbar-nav .nav-item:hover {
  font-weight: bold;
}

.carousel-btn {
  border-radius: 30px !important;
  border-width: 2px !important;
  font-size: 30px !important;
  background-color: #fff !important;
  color: #000 !important;
}

.carousel-btn:hover {
  background-color: green !important;
  color: white !important;
}

@media (min-width: 769px) and (max-width: 1920px) {
  .carousel-caption h3 {
    font-size: 60px !important;
  }

  .carousel-caption span {
    font-size: 20px;
  }
  .carousel-caption div a {
    font-size: 12px;
  }
  .carousel-btn {
    border-radius: 30px !important;
    border-width: 2px !important;
    font-size: 30px !important;
    background-color: #fff !important;
    color: #000 !important;
  }
}

@media (max-width: 768px) {
  .carousel-caption h3 {
    font-size: 25px !important;
  }

  .carousel-caption div a {
    font-size: 12px;
  }
  .carousel-btn {
    border-radius: 30px !important;
    border-width: 2px !important;
    font-size: 20px !important;
    background-color: #fff !important;
    color: #000 !important;
  }
}

@media (max-width: 424px) {
  .carousel-caption h3 {
    font-size: 20px !important;
  }

  .carousel-caption span {
    font-size: 15px;
  }
  .carousel-caption div a {
    font-size: 12px;
  }
  .carousel-btn {
    border-radius: 30px !important;
    border-width: 2px !important;
    font-size: 15px !important;
    background-color: #fff !important;
    color: #000 !important;
  }
}
.swal2-html-container {
  text-align: left !important;
  /*padding: 20px 0;*/
}

.nav-toggle-active {
  background-color: #198754 !important;
}

.swal2-html-container {
  margin-left: auto !important;
  margin-right: auto !important;
}

/* html:not([dir=rtl]) .input-group-merge .input-group-text:last-child {
    border-left: 0;
}
.input-group-text {
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.input-group-text {
    background-clip: padding-box;
}*/

/* .fc {
  border-right: none !important;
} */

.input-group-text {
  /* padding: 0.4375rem 0.875rem !important; */
  line-height: 1.53 !important;
  color: #697a8d !important;
  background-color: #fff !important;
  padding: 0 !important;
}

.credit-card-type-input-img {
  /* width: 50px; */
}

/* .form-control:focus {
  border-right: 0 !important;
} */
/* .form-control:focus + span.input-group-text {
  border-left: 0 !important;
} */
/* .form-control:focus, .form-control:focus + span.input-group-text { */
/* border: none !important; */
/* box-shadow: none !important; */
/* border-color: rgba(126, 239, 104, 0.8) !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(126, 239, 104, 0.6) !important;
  outline: 0 none !important; */
/* border-color: #28a745 !important;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important; */
/* border-radius: 8px !important; */
/* } */

/* div.focus-group:focus-within .fc{
  border-color: #28a745 !important;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important;
} */

/* .div.focus-group:has(:focus-within) .fc {
  border-color: #28a745 !important;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important;
} */

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.3) !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px !important;
}

/* .focus-group:has(.fc:focus) {
  border-radius: 0.375rem !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
} */

.nav-gap-3 {
  gap: 1rem !important;
}

.nav.nav-tabs .nav-item .nav-link {
  color: white;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.nav.nav-tabs .nav-item .nav-link:hover {
  color: black !important;
}

.text-center {
  text-align: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

/* 
Bugs Styling
*/

.carousel-btn {
  font-size: 1rem !important;
}

.tri-card-p {
  font-size: 1.2rem !important;
}

.relative-margin-flogo {
  margin-top: -24px !important;
}

.text-justify {
  text-align: justify !important;
}
.btn-outline-light {
  padding: 0.5rem 1.3rem !important;
}
.btn-outline-light.px-4 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.history-ep-text {
  font-size: 1.52rem !important;
  line-height: 1.75;
}

.card .card-body {
  padding: 0 18px 25px 18px !important;
}

.nav-menu-link {
  font-size: 1.2rem !important;
}
.sm-display-block {
  display: none !important;
}

/* #carsoule-caption {
  padding-top: 0.5rem !important;
} */

/* #carsoule-caption h3 {
  padding-top: 0.75rem !important;
  margin: 0 !important;
  line-height: 0.5 !important;
} */

.text-green {
  color: #3c7e46 !important;
}

@media only screen and (min-width: 1860px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 80vw !important;
  }
  .site-logo {
    width: 280px !important;
    height: auto;
  }
}

@media only screen and (min-width: 2560px) {
  /* #carsoule-caption {
    padding-top: 3rem !important;
  } */
  .nav-menu-link {
    font-size: 1.5rem !important;
    font-weight: 500 !important;
  }
  .site-logo {
    width: 300px !important;
    height: auto;
  }
  .nav-logo {
    width: 350px !important;
    height: 95px !important;
    display: block;
  }
  .top-bar-text {
    font-size: 16px !important;
  }

  .carousel-btn {
    font-size: 1.5rem !important;
  }
  .carousel-caption {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    /* box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px; */
    /* box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px; */
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
      rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  .history-ep-text {
    font-size: 2rem !important;
  }
}

.nav-menu-link {
  font-weight: 500 !important;
}

.top-bar-text {
  font-size: 15px;
}

.nav-logo {
  width: 250px !important;
  height: 80px !important;
}

.z-index-999999 {
  z-index: 999999 !important;
}

.search-bar.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: static !important;
  flex: 0 1 !important;
  min-width: 500px !important;
}

.search-bar.input-group {
  /* position: static !important; */
  justify-content: flex-end !important;
}

.our-overview-area .nav-tabs-02 {
  /* justify-content: space-between !important; */
}

.swal2-html-container {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.pagination .page-item {
}

.pagination .page-item .page-link {
  width: 3rem !important;
  height: 3rem !important;
  margin: 0.1rem !important;
}

.page-link {
  padding: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* padding-top: 0.1rem !important; */
}
.carousel-caption span,
.carousel-caption h3 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.footer-logo {
  width: 300px !important;
}
.footer-text {
  padding-right: 3rem !important;
}
/* .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select{
  border-radius: 8px !important;
} */

.navbar {
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  line-height: 1.5 !important;
  letter-spacing: 0.2px !important;
}

.navbar-toggler {
  font-size: 1rem !important;
  padding: 0.2rem 0.5rem !important;
}

.nav-logo {
  height: 80px !important;
}

.red-box-shadow {
  box-shadow: rgba(138, 5, 5, 0.24) 0px 3px 8px;
}

#zelle-poster-logo {
  width: 100%;
}

.card #card-body {
  padding: 0 35px 15px 35px !important;
}

.causes-details {
  border: none !important;
  /* border-style: solid !important; */
}

.display-hidden {
  display: hidden !important;
}

.donation-type-icon {
  font-size: 3.8em;
}

.d-cbox-flex {
  display: flex;
  flex-direction: row;
}

.donation-box {
  display: flex;
  flex-direction: row;
}

.lg-content-hidden {
  display: none !important;
}

@media only screen and (max-width: 1440px) {
  .site-logo {
    width: 300px !important;
    height: auto;
  }
  .nav-logo {
    width: 275px !important;
  }
  .tri-card-p {
    font-size: 1rem !important;
  }
  .carousel-caption h3 {
    font-size: 50px !important;
  }
  .row {
    --bs-gutter-x: 0.751rem !important;
  }
}
@media only screen and (max-width: 1212px) and (min-width: 992px) {
  .col-smlg-6 {
    width: 50% !important;
    /* flex-direction: column; */
  }
  .donation-box {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1212px) {
  .d-cbox-flex {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 992px) {
  .btn.btn-primary.btn-footer {
    padding: 0.25rem 0.25rem !important;
  }
  .pt-smlg-2 {
    padding-top: 7vh;
  }
}

@media only screen and (max-width: 1024px) {
  .site-logo {
    width: 220px !important;
    height: auto;
  }
  .nav-logo {
    width: 200px !important;
  }
  .nav-menu-link {
    font-size: 1.1rem !important;
  }
  .search-bar.input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    min-width: 320px !important;
  }
  .nav-gap-3 {
    gap: 0.2rem !important;
  }

  .our-overview-area .nav-tabs-02 .nav-link {
    font-size: 16px !important;
  }
  .carousel-caption h3 {
    font-size: 44px !important;
  }
  .btn-outline-light {
    padding: 0.45rem 1.3rem !important;
  }
  .project-image-area.pt-130 {
    padding-top: 100px !important;
  }
  .project-image-area.pb-100 {
    padding-bottom: 75px !important;
  }
  .donation-type-icon {
    font-size: 3.2em;
  }
  .md-cause-f {
    font-size: 1.2rem !important;
  }
  .md-cause-f.fw-bold {
    font-weight: 600 !important;
  }
}
@media only screen and (max-width: 991px) {
  .footer-logo {
    width: 400px !important;
  }
  .footer-text {
    padding-right: 0 !important;
  }
  .nav-menu-link {
    font-size: 1rem !important;
  }
  .donate-text-center {
    text-align: center !important;
  }
  .donate-btn-center {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .cNDqcp {
    left: 93vw !important;
    bottom: 55px !important;
    font-size: 2.7rem !important;
  }
  /* .flex-md-row{
    flex-direction: column !important ;
  } */
}
@media only screen and (max-width: 768px) {
  .footer-logo {
    width: 300px !important;
  }
  .donate-text-center {
    text-align: center !important;
  }
  .md-hidden {
    display: none !important;
  }
  #zelle-poster-logo {
    width: 50%;
  }
  h2.contact-title {
    font-size: 1.75rem !important;
  }
  .md-box-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .md-mb-0 {
    margin-bottom: 1rem !important;
  }
  .contact-form .input-box h5 {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 767px) {
  .site-logo {
    width: 210px !important;
    height: auto;
  }
  .our-overview-area .nav-tabs-02 {
    justify-content: space-around !important;
  }
  .txt-md-center {
    /* text-align: center !important; */
    /* align-items: center !important; */
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .nav-menu-link {
    font-size: 1.25rem !important;
  }
  .row {
    --bs-gutter-x: 1rem !important;
  }
  .project-image-area.pt-130 {
    padding-top: 75px !important;
  }
  .project-image-area.pb-100 {
    padding-bottom: 60px !important;
  }
  .project-image-area.container-sm,
  .container {
    min-width: 75vw !important;
  }
  .project-image-text-area.pb-60 {
    padding-bottom: 40px !important;
  }
  .donationTitle {
    width: 100%;
    margin-right: auto !important;
  }
  .d-sm-flex {
    display: flex !important;
    flex-direction: column !important;
  }
  .sm-hidden {
    display: none !important;
  }

  .sm-font {
    font-size: 1rem !important;
    text-transform: capitalize;
    text-align: center;
  }
  .post__img {
    border: 1px solid #dbdee0;
    padding: 5px;
    width: 100% !important;
    margin-right: 2px !important;
  }
  .post__img img {
    width: 100% !important;
  }
  .latest-comments li {
    padding-bottom: 10px !important;
  }
  .comments__avatar {
    float: left !important;
    margin-right: 5px !important;
    overflow-y: auto;
  }
  .cNDqcp {
    /* left: 9vw !important; */
    font-size: 2.4rem !important;
    bottom: 45px !important;
  }
}
@media only screen and (max-width: 758px) {
  .nav-logo {
    width: 250px !important;
  }

  .navbar-nav {
    margin: 30px 0;
  }

  .search-bar.input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    min-width: 180px !important;
  }
  .txt-md-center {
    text-align: center !important;
  }
}
@media only screen and (max-width: 592px) {
  .cNDqcp {
    left: 91vw !important;
    font-size: 2.2rem !important;
    /* bottom: 45px !important; */
  }
  .nav-logo {
    height: 60px !important;
  }
  .search-bar {
    /* display: none !important; */
  }
  .our-overview-area .nav-tabs-02 {
    justify-content: space-around !important;
  }
  /* .sm_text-center {
    text-align: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  } */
  .top-bar-text {
    font-size: 11px;
  }
  .carousel-caption h3 {
    font-size: 30px !important;
  }
  .btn-outline-light,
  .btn-light {
    padding: 0.35rem 1rem !important;
  }
  .sm-icon-size {
    font-size: 3.25rem !important;
  }
  .history-ep-text {
    font-size: 1.2rem !important;
    line-height: 1.6;
  }
  .card .card-body {
    padding: 0 !important;
  }

  .footer-text {
    padding-right: 0.1rem !important;
  }
  .nav-menu-link {
    font-size: 1.1rem !important;
  }
  .row {
    --bs-gutter-x: 0.5rem !important;
  }
  .navbar-toggler {
    font-size: 1.1rem !important;
    padding: 0.25rem 0.5rem !important;
  }
  .pagination .page-item .page-link {
    font-size: 14px !important;
  }
  .pagination .page-item .page-link {
    width: 2rem !important;
    height: 2rem !important;
  }
  .project-image-area.pt-130 {
    padding-top: 60px !important;
  }
  .project-image-area.pb-100 {
    padding-bottom: 40px !important;
  }
  .project-image-text-area.pb-60 {
    padding-bottom: 25px !important;
  }

  .quarbani-checkbx {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
  }
  .quarbani-checkbx input[type='checkbox'] {
    margin-top: 4px;
    /* align-items: flex-start !important; */
  }
  .pb-xs-2 {
    padding-bottom: 0.6rem !important;
  }
  .comments__content h5 {
    font-size: 1rem !important;
  }
  .comments__content p {
    font-size: 0.85rem !important;
  }
  .form-control,
  .widget-search-box .subscribe-form .form-control {
    /* padding: 0.25rem 0.75rem !important; */
    height: 50px !important;
  }
  .post-form-area .input-text textarea {
    height: 100px !important;
  }
  .theme_btn {
    /* padding: 16px 26px !important; */
  }
  .sm-display-block {
    display: block !important;
  }
  .sm-mb-0 {
    margin-bottom: 1rem !important;
  }
  .sm-pb-2 {
    padding-bottom: 2rem !important;
  }
  .sm-pt-2 {
    padding-top: 2rem !important;
  }

  .content-sm-center {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .sm-content-hidden {
    display: none !important;
  }
  /* .btn-outline-light.px-4{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  } */

  .col-12 {
    width: 100% !important;
  }
  .lg-content-hidden {
    display: block !important;
  }
}
@media only screen and (max-width: 375px) {
  .site-logo {
    width: 180px !important;
    height: auto;
  }
  .cNDqcp {
    left: 88vw !important;
    font-size: 2rem !important;
    bottom: 40px !important;
  }
  .nav-logo {
    width: 180px !important;
    height: 60px !important;
  }
  .navbar-toggler {
    font-size: 0.9rem !important;
    padding: 0.25rem 0.45rem !important;
  }
  .navbar-toggler {
    margin-left: auto;
    margin-right: auto;
  }
  .blog-tag {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .nav-menu-link {
    font-size: 1rem !important;
  }
  .footer-logo {
    width: 100% !important;
  }
  .flex-xs-column.flex-row {
    flex-direction: column !important;
  }
  .sm-font {
    font-size: 0.85rem !important;
  }
}

/*radio buttons */
.radio-btn-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.radio-btn-group label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.radio-btn-group input[type='radio'] {
  appearance: none;
  border: 2px solid #ccc;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
}

.radio-btn-group input[type='radio']:checked {
  border-color: #007bff;
}

.radio-btn-group input[type='radio']:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.video-player {
  height: 300px;
  width: 100%;
}

.zoom-img {
  overflow: hidden;
}

/* .zoom-img img {
  transition: transform 0.3s;
}

.zoom-img img:hover {
  transform: scale(1.5);
} */

/* .blog-carosul-content-height {
  height: 90vh;
  width: 100vw;
} */

.blog-carosul-content-height img,
.blog-carosul-content-height video {
  min-width: 350px;

  height: auto;
  width: auto;
  max-height: 80vh;
  max-width: 100vw;
}
/* 
@media only screen and (max-width: 370px) {
  .blog-carosul-content-height {
    height: 150px;
  }
}

@media only screen and (max-width: 400px) and (min-width: 371px) {
  .blog-carosul-content-height {
    height: 200px;
  }
}

@media only screen and (max-width: 450px) and (min-width: 401px) {
  .blog-carosul-content-height {
    height: 210px;
  }
}

@media only screen and (max-width: 800px) and (min-width: 451px) {
  .blog-carosul-content-height {
    height: 400px;
  }
}

@media only screen and (max-width: 1050px) and (min-width: 801px) {
  .blog-carosul-content-height {
    height: 500px;
  }
}

@media only screen and (min-width: 1051px) {
  .blog-carosul-content-height {
    height: 600px;
  }
}
 */
.youtube-video-modal {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  height: 600px;
  width: 1000px;
}
.carosel-items-customization {
  height: 100vh;
  width: 100%;
}

.text-capt {
  font-size: 2rem !important;
}
.carousel-btn-text {
  font-size: 1.5rem !important;
}
.dQuPGT {
  left: auto !important;
  right: 30px !important;
  width: auto !important;
}

@media only screen and (max-width: 2000px) {
  .youtube-video-modal {
    height: 600px;
    width: 1000px;
  }
  .carosel-items-customization {
    height: 100vh;
    width: 100%;
  }
}
@media only screen and (max-width: 1600px) {
  .carosel-items-customization {
    height: 850px;
    width: 100%;
  }
}
@media only screen and (max-width: 1400px) {
  .youtube-video-modal {
    height: 500px;
    width: 900px;
  }
  .carosel-items-customization {
    height: 700px;
    width: 100%;
  }
  .text-capt {
    font-size: 1.8rem !important;
  }
  .carousel-btn-text {
    font-size: 1.3rem !important;
  }
}
@media only screen and (max-width: 950px) {
  .youtube-video-modal {
    height: 400px;
    width: 690px;
  }
  .carosel-items-customization {
    height: 600px;
    width: 100%;
  }
  .text-capt {
    font-size: 1.6rem !important;
  }
}
@media only screen and (max-width: 700px) {
  .dQuPGT {
    right: 10px !important;
  }
  .youtube-video-modal {
    height: 370px;
    width: 540px;
  }
  .carosel-items-customization {
    height: 530px;
    width: 100%;
  }
  .text-capt {
    font-size: 1.45rem !important;
  }
  .carousel-btn-text {
    font-size: 1rem !important;
  }
}
@media only screen and (max-width: 575px) {
  .sm-text-center {
    text-align: center;
    width: 100%;
  }
}
@media only screen and (max-width: 550px) {
  .youtube-video-modal {
    height: 340px;
    width: 440px;
  }
  .carosel-items-customization {
    height: 480px;
    width: 100%;
  }
  .text-capt {
    font-size: 1.3rem !important;
  }
  .carousel-btn-text {
    font-size: 0.7rem !important;
  }
}
@media only screen and (max-width: 450px) {
  .youtube-video-modal {
    height: 300px;
    width: 380px !important;
  }
  .carosel-items-customization {
    height: 420px;
    width: 100%;
  }
  .text-capt {
    font-size: 1rem !important;
  }
}
@media only screen and (max-width: 400px) {
  .youtube-video-modal {
    height: 270px;
    width: 320px !important;
  }
  .carosel-items-customization {
    height: 380px;
    width: 100%;
  }
}
